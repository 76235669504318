cs.ns('app.ui.root')
app.ui.root.model = cs.clazz({
    extend:   app.fw.root.model,
    mixin:    [
        app.ui.trait.root.businessservices.model,
        app.ui.trait.root.appservices.model,
        app.ui.trait.root.hash.model,
        app.ui.trait.root.modaldialogs.model,
        app.ui.trait.root.websocketservices.model,
    ],
    dynamics: {},
    protos:   {

        create () {
            this.base()
            cs(this).model({
                'global:data:userLanguage': { value: 'de', valid: 'string', store: true },
                'event:showOSC':            { value: false, valid: 'boolean', autoreset: true },
                'event:keepOSCVisible':     { value: false, valid: 'boolean', autoreset: true },
                'state:OSCVisible':         { value: true, valid: 'boolean' },
                'state:uiEnabled':          { value: true, valid: 'boolean' },
                'global:state:autoplay':    { value: true, valid: 'boolean' }
            })
        }
    }
})